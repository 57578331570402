<template>
    <div>暂未开放</div>
</template>

<script>
export default {
    name: "sms",

    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>